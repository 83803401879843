<div fxLayout="row">
    <span fxLayout="row" fxFlex="90%">
        <!-- <div>{{this.trade.created | date: 'dd.MM.yyyy HH:mm:ss'}}</div>
        <div fxLayoutAlign="start center" class="light-gray-font">{{this.trade?.api}} => {{this.trade.analyseApi}}</div> -->
        <h2 fxFlex mat-dialog-title>{{this.config.name}} </h2>
        <h2 fxFlex mat-dialog-title [hidden]="this.config.type !== 'CoinTrader'">({{this.config.exchange}})</h2>
    </span>

    <button mat-icon-button id="close" (click)="close()" type="button">
        <mat-icon svgIcon="close"></mat-icon>
    </button>

</div>

<mat-dialog-content >
    <!-- <ngx-property-grid [options]="editor" (change)="edit()">
    </ngx-property-grid> -->
    <mat-slide-toggle [(ngModel)]="this.config.enabled">Configuration enabled</mat-slide-toggle>
    <json-editor [options]="editorOptions" [data]="this.config.configuration" (change)="edit($event)"></json-editor>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row">
    <button  mat-stroked-button color="primary" (click)="create()">Save as new config</button>
    <span fxFlex></span>
    <button [disabled]="!changed"  mat-stroked-button color="primary" (click)="save()">Update</button>
    <button mat-stroked-button color="primary" (click)="dialogRef.close()">Cancel</button>
</mat-dialog-actions>
