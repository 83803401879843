import { Component, OnInit, EventEmitter, ViewChild, Output, Input, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { User } from 'src/app/models/user';
import { Observable, BehaviorSubject } from 'rxjs';
import { TickerService } from '../../services/ticker.service';
import { Platform } from '@angular/cdk/platform';
import { InstanceSelectionDialogComponent } from '../dialogs/instance-selection-dialog/instance-selection-dialog.component';
import { PriceTickerService } from 'src/app/services/price-ticker.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  @ViewChild(MatInput) searchInput: MatInput;
  @Output() toggle = new EventEmitter<void>();
  @Input() user: User;
  @HostListener('window:resize', ['$event'])
  avatarColor: string;
  authService: AuthenticationService;
  initials$ = new BehaviorSubject<string>(null);

  BTCUSDup: boolean;
  BTCUSD = 0;
  BTCEURup: boolean;
  BTCEUR = 0;
  ETHUSDup: boolean;
  ETHUSD = 0;
  ETHEURup: boolean;
  ETHEUR = 0;

  constructor(
    private router: Router,
    authService: AuthenticationService,
    private tickerService: PriceTickerService,
    private dialog: MatDialog
  ) {
    this.authService = authService;
  }

  async ngOnInit() {
    this.initials$.next(this.getEmployeeInitials());
    this.tickerService.currentPriceTicker$.subscribe(tick => {

      if (!tick) { return; }

      this.BTCUSDup = tick.BTCUSD && tick.BTCUSD > this.BTCUSD;
      this.BTCUSD = tick.BTCUSD;

      this.BTCEURup = tick.BTCEUR && tick.BTCEUR > this.BTCEUR;
      this.BTCEUR = tick.BTCEUR;

      this.ETHUSDup = tick.ETHUSD && tick.ETHUSD > this.ETHUSD;
      this.ETHUSD = tick.ETHUSD;

      this.ETHEURup = tick.ETHEUR && tick.ETHEUR > this.ETHEUR;
      this.ETHEUR = tick.ETHEUR;
    });
  }

  showInstances() {
    this.dialog.open(InstanceSelectionDialogComponent, { minWidth: '400px' });
  }

  navigate(uri) {
    this.router.navigateByUrl(uri);
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('login');
  }

  getEmployeeInitials(): string {
    let result = '';

    if (!this.user) {
      return result;
    }

    if (this.user.username) {
      result += this.user.username.substr(0, 1).toUpperCase();
    }

    if (this.user.lastName) {
      result += this.user.lastName.substr(0, 1).toUpperCase();
    }

    return result;
  }

}
