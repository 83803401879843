import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { LoaderComponent } from './components/loader/loader.component';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AppRoutingModule } from '../app-routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NavigationComponent } from './components/navigation/navigation.component';
import { RefreshTimerModule } from '../components/refresh-timer/refresh-timer.module';
import { InstanceSelectionDialogComponent } from './components/dialogs/instance-selection-dialog/instance-selection-dialog.component';


@NgModule({
  declarations: [
    ToolbarComponent,
    MainLayoutComponent,
    LoaderComponent,
    NavigationComponent,
    InstanceSelectionDialogComponent,
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatDividerModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    FlexLayoutModule,
    AppRoutingModule,
    FormsModule,
    MatDialogModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSnackBarModule,
    RefreshTimerModule,
  ],
  entryComponents: [
    InstanceSelectionDialogComponent
  ],
  exports: [
    MainLayoutComponent,
  ],
})
export class LayoutModule { }
