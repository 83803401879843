import { Component, OnInit, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router, NavigationEnd } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { filter, tap, map, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
  @ViewChild(MatSidenav) sidenav: MatSidenav;
  mode: 'over' | 'push' | 'side' = 'side';
  opened = true;

  elisa = false;
  trader = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private authService: AuthenticationService,
    private changeDetectorRef: ChangeDetectorRef,
    router: Router,
    breakpointObserver: BreakpointObserver,
  ) {
    // Close sidenav on small screens after navigating
    this.subscriptions.push(
      router.events.pipe(
        filter(e => e instanceof NavigationEnd), // only on routing
        filter(e => this.mode === 'over'), // only on smalls screens
        // tap(() => this.sidenav.close()),
        tap(() => this.changeDetectorRef.markForCheck())
      ).subscribe()
    );

    // subscribe event which will be triggered every time, the screen-size switches between small and large
    this.subscriptions.push(
      breakpointObserver.observe('(max-width: 959px)').pipe(
        map(state => state.matches),
        distinctUntilChanged(),
        tap(isSmall => this.screenSizeChanged(isSmall))
      ).subscribe()
    );

    this.elisa = this.authService.currentUserValue.roles && this.authService.currentUserValue.roles.indexOf('ElisaTrader') !== -1;
    this.trader = this.authService.currentUserValue.roles && this.authService.currentUserValue.roles.indexOf('CoinTrader') !== -1;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  logout() {
    this.authService.logout();
  }

  toggle() {
    this.sidenav.toggle();
    this.changeDetectorRef.markForCheck();
  }

  private screenSizeChanged(isSmall: boolean) {
    if (isSmall) {
      this.opened = false;
      this.mode = 'over';
    } else {
      this.opened = true;
      this.mode = 'side';
    }
    this.changeDetectorRef.markForCheck();
  }

}
