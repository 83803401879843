import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { InstanceFilter } from '../models/instance-filter';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.initFilter();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public get InstanceFilter(): string[] {
        const instanceNames = Array<string>();
        if (!this.currentUserValue || !this.currentUserValue.instanceFilter) { return instanceNames; }
        this.currentUserValue.instanceFilter.forEach(c => {
            if (!c.uiDeselected) {
                instanceNames.push(c.instance);
            }
        });
        return instanceNames;
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${environment.url}/login`, { username, password })
            .pipe(map(user => {
                // store user details and basic auth credentials in local storage to keep user logged in between page refreshes
                user.authdata = window.btoa(username + ':' + password);
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                this.initFilter();
                return user;
            }));
    }

    initFilter(){
        const instanceFilter = new Array<InstanceFilter>();
        if (!this. currentUserValue) { return; }
        if (this.currentUserValue.instances)
        {
            this.currentUserValue.instances.forEach(i => {
                const fi = new InstanceFilter();
                fi.instance = i;
                fi.uiDeselected = false;
                instanceFilter.push(fi);
        });
        }
        this.currentUserValue.instanceFilter = instanceFilter;
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}
