import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { LoginComponent } from './components/login/login.component';
import { MainLayoutComponent } from './layout/components/main-layout/main-layout.component';


const routes: Routes = [
  {
    path: 'login', component: LoginComponent
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'elisa',
        loadChildren: () => import (
          './pages/ig-trader-main-page/ig-trader-main-page.module').then(m => m.IgTraderMainPageModule),
          canActivate: [AuthGuard]
      },
      {
        path: 'trader',
        loadChildren: () => import (
          './pages/coin-trader-main-page/coin-trader-main-page.module').then(m => m.CoinTraderMainPageModule),
          canActivate: [AuthGuard]
      },
      {
        path: 'report',
        loadChildren: () => import (
          './pages/coin-trader-report-page/coin-trader-report-page.module').then(m => m.CoinTraderReportPageModule),
          canActivate: [AuthGuard]
      },
      {
        path: 'configuration',
        loadChildren: () => import (
          './pages/configuration-page/configuration-page.module').then(m => m.ConfigurationPageModule),
          canActivate: [AuthGuard]
      },
      {
        path: '**',
        redirectTo: 'trader',
        pathMatch: 'full'
      }
    ]
  },
  // REDIRECT EVERYTHING ELSE
  {
    path: '',
    redirectTo: 'trader',
    pathMatch: 'full'
  },
  { path: 'header', loadChildren: () => import('./pages/configuration-page/configuration-page.module').then(m => m.ConfigurationPageModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
