import { SimpleChanges } from '@angular/core';

export class GenericStorage {
    static get(key: string) {
        try {
            return localStorage.getItem(key);
        } catch (err) {
            return sessionStorage.getItem(key);
        }
    }

    static set(key: string, value: any) {
        try {
            return localStorage.setItem(key, value);
        } catch (err) {
            return sessionStorage.setItem(key, value);
        }
    }
}

export const hasChanged = (changes: SimpleChanges, propertyName: string) => {
    const change = changes[propertyName];
    return !!change && change.currentValue !== change.previousValue;
};

export const removeFromArray = (item: any, array: Array<any>) => {
    const index: number = array.indexOf(item);
    if (index !== -1) {
        array.splice(index, 1);
    }
};

export const setObjectValues = (objectA, objectB) => {
    const objectAKeys = Object.keys(objectA);
    const objectBKeys = Object.keys(objectB);

    if (typeof objectA === typeof objectB) {
        for (const key of objectBKeys) {
            objectA[key] = objectB[key];
            removeFromArray(key, objectAKeys);
        }
        for (const key of objectAKeys) {
            objectA[key] = objectB[key];
        }
    }
};


