<mat-toolbar>
    <mat-toolbar-row>
        <button (click)=" toggle.emit()" mat-button>
            <mat-icon class="menu-icon" svgIcon="menu"></mat-icon>
        </button>
        <div fxShow fxHide.lt-sm routerLink=""[style.margin-right.px]= "100">Tradermanger</div>

        <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="150px">

            <div fxLayout = "row">
                <mat-icon *ngIf="BTCUSDup" class="green-icon">keyboard_arrow_up</mat-icon>
                <mat-icon *ngIf="!BTCUSDup" class="red-icon">keyboard_arrow_down</mat-icon>
                <div class="toolbar-currency">BTC: {{BTCUSD}} $</div>
            </div>

            <div fxShow fxHide.lt-md fxLayout = "row">
                <mat-icon *ngIf="BTCEURup" class="green-icon">keyboard_arrow_up</mat-icon>
                <mat-icon *ngIf="!BTCEURup" class="red-icon">keyboard_arrow_down</mat-icon>
                <div class="toolbar-currency">BTC: {{BTCEUR}} €</div>
            </div>

            <div fxShow fxHide.lt-lg fxLayout = "row">
                <mat-icon *ngIf="ETHUSDup" class="green-icon">keyboard_arrow_up</mat-icon>
                <mat-icon *ngIf="!ETHUSDup" class="red-icon">keyboard_arrow_down</mat-icon>
                <div class="toolbar-currency">ETH: {{ETHUSD}} $</div>
            </div>

            <div fxShow fxHide.lt-xl fxLayout = "row">
                <mat-icon *ngIf="ETHEURup" class="green-icon">keyboard_arrow_up</mat-icon>
                <mat-icon *ngIf="!ETHEURup" class="red-icon">keyboard_arrow_down</mat-icon>
                <div class="toolbar-currency">ETH: {{ETHEUR}} €</div>
            </div>

        </div>

        <div fxFlex></div>


        <ng-container *ngIf="user">
            <div *ngIf="initials$ | async; let initials;" id="user" fxLayout="row">
                <button mat-button [matMenuTriggerFor]="userMenu">
                    <div class="avatar-initials">
                        <span>{{initials}}</span>
                    </div>
                </button>
            </div>
            <mat-menu #userMenu="matMenu" xPosition="before" yPosition="below">
                <div class="usercontainer" fxLayout="column">
                    <span class="username">{{ user.firstName }} {{ user.lastName }}</span>
                    <span class="usermail">{{ user.username }}</span>
                </div>
                <mat-divider class="menu-divider"></mat-divider>
                <!-- <button (click)="showInstances()" mat-menu-item>Instanzen</button> -->
                <button (click)="navigate('configuration')" mat-menu-item>Configuration</button>
                <mat-divider></mat-divider>
                <button (click)="logout()" mat-menu-item>Abmelden</button>
            </mat-menu>

        </ng-container>

    </mat-toolbar-row>
</mat-toolbar>

<ng-template #menuTemplate>
    <button (click)=" toggle.emit()" mat-button>
        <mat-icon class="menu-icon" svgIcon="menu"></mat-icon>
    </button>
</ng-template>