import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BaseConfig, TraderConfig } from 'src/app/models/config';
import { InstanceFilter } from 'src/app/models/instance-filter';

@Component({
  selector: 'app-instance-selection-dialog',
  templateUrl: './instance-selection-dialog.component.html',
  styleUrls: ['./instance-selection-dialog.component.scss']
})
export class InstanceSelectionDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InstanceSelectionDialogComponent>,
    private authService: AuthenticationService
  ) { }

  configs = new Array<InstanceFilter>();

  allButton = 'Unselect all';

  ngOnInit() {
    this.configs = this.authService.currentUserValue.instanceFilter;
  }

  edit(iName) {
    this.authService.currentUserValue.instanceFilter.forEach(config => {
      if (config.instance === iName) {
        config.uiDeselected = !config.uiDeselected;
      }
    });
  }

  qsFilter() {
    this.authService.currentUserValue.instanceFilter.forEach(config => {
      const sp = config.instance.split('-');

      if (sp.length !== 3) {
        if (config.instance.includes('ETH') || config.instance.includes('USDT')) {
          config.uiDeselected = false;
        } else {
          config.uiDeselected = true;
        }
      }
      if (sp.length === 3) {
        config.uiDeselected = false;
      }
    });
  }

  invert() {
    this.authService.currentUserValue.instanceFilter.forEach(config => {
      if (this.allButton === 'Unselect all') {
        config.uiDeselected = true;
      } else {
        config.uiDeselected = false;
      }
    });

    if (this.allButton === 'Unselect all') {
      this.allButton = 'Select all';
    } else {
      this.allButton = 'Unselect all';
    }
  }

  save() {
    this.dialogRef.close();
  }

}
