<div fxLayout="row">
    <h2 fxFlex mat-dialog-title>Trader-Konfigurationen</h2>
    <button mat-icon-button id="close" (click)="dialogRef.close()" type="button">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<mat-dialog-content fxLayout="column" fxLayoutGap="10px">

    <div fxLayout="row" fxLayoutGap="20px">
        <button mat-icon-button color="primary" (click)="qsFilter()">Only QS</button>
        <button mat-icon-button color="primary" (click)="invert()">{{allButton}}</button>
    </div>
    <div fxLayout="column" fxLayoutGap="10px">
        <label class="config-label">Instanzen:</label>
        <mat-radio-group *ngFor="let li of configs">
            <mat-checkbox color="primary" [(ngModel)]="!li.uiDeselected" (change)="edit(li.instance)">{{li.instance}}</mat-checkbox>
        </mat-radio-group>
    </div>

</mat-dialog-content>
<mat-dialog-actions fxLayout="row">
    <span fxFlex></span>
    <button mat-stroked-button color="primary" (click)="save()">Ok</button>
</mat-dialog-actions>
