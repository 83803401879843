<mat-sidenav-container class="sidenav-container" fxFill fxLayout="column" fxLayoutAlign="end0">
  <mat-sidenav [mode]="mode" [opened]="opened">
    <nav>
      <ul>
        <mat-divider class="title-divider no-line"></mat-divider>
        <ng-container>

          <p class="title">Sides</p>
          <li *ngIf="elisa">
            <a mat-button routerLink="/elisa" routerLinkActive="active">
              <ng-container>Elisa-Trader</ng-container>
            </a>
          </li>


          <li *ngIf="trader">
            <a mat-button routerLink="/trader" routerLinkActive="active">
              <ng-container>Coin-Trader</ng-container>
            </a>
            <a mat-button routerLink="/report" fxLayoutAlign="start center" routerLinkActive="active">
              <ng-container>Report</ng-container>
            </a>
          </li>

        </ng-container>

      </ul>
    </nav>
  </mat-sidenav>
  <ng-content></ng-content>
</mat-sidenav-container>