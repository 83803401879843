import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { meta, PropertyGridModule } from 'ngx-property-grid';
import {NgxTemplateModule} from 'ngx-template';
import { BaseConfig, TraderConfig } from 'src/app/models/config';
import { CointraderService } from 'src/app/services/cointrader.service';

@Component({
  selector: 'app-edit-config-dialog',
  templateUrl: './edit-config-dialog.component.html',
  styleUrls: ['./edit-config-dialog.component.scss']
})
export class EditConfigDialogComponent implements OnInit {

  public editor: {};
  public editorOptions: JsonEditorOptions;
  changed = false;
  config: BaseConfig;
  @ViewChild(JsonEditorComponent, { static: false }) editor2: JsonEditorComponent;

  constructor(
    public dialogRef: MatDialogRef<EditConfigDialogComponent>,
    private traderService: CointraderService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.config = data.config;

    if (typeof(this.config.configuration) !== 'object') {
          this.config.configuration = JSON.parse(this.config.configuration);
    }

    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.mode = 'tree';
   }

  ngOnInit(): void {
  }

  async save() {
    switch (this.config.type){
      case 'CoinTrader':
        this.config.configuration = this.editor2.getText();
        this.config.name = JSON.parse(this.config.configuration)?.InstanceName;
        break;
      case 'MessageTrader':
        this.config.configuration = this.editor2.getText();
        this.config.name = JSON.parse(this.config.configuration)?.Name;
        break;
      case 'ElisaTrader':
        this.config.configuration = this.editor2.getText();
        break;
      }

    this.config = await this.traderService.updateConfig(this.config.id, this.config).toPromise();
    // const succ = await this.traderService(this.trade.id, this.action).toPromise();
    this.dialogRef.close();
  }
  async close() {
    this.dialogRef.close();
  }

  async create() {
    switch (this.config.type){
    case 'CoinTrader':
      this.config.configuration = this.editor2.getText();
      const c = JSON.parse(this.config.configuration);
      this.config.name = c?.InstanceName;
      this.config.exchange = c?.ExchangeName;
      break;
    case 'MessageTrader':
      this.config.configuration = this.editor2.getText();
      this.config.name = JSON.parse(this.config.configuration)?.Name;
      break;
    case 'ElisaTrader':
      this.config.configuration = this.editor2.getText();
      break;
    }


    // todo await sonst wird es nicht ausgeführt
    this.config = await this.traderService.createConfig(this.config).toPromise();
    // const succ = await this.traderService(this.trade.id, this.action).toPromise();
    this.dialogRef.close();
  }

  edit(event) {
    if (!this.changed)
    {
      this.changed = true;
    }
  }

}
