<div fxLayout="row" fxLayoutAlign="center center">
    <div >
        <h4>Login</h4>
        <div >
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" fxLayout="column" fxLayoutAlign="space-around center">
                <mat-form-field appearance="outline" color="primary">
                    <mat-label>Username</mat-label>
                    <input type="text" formControlName="username" matInput placeholder="Username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                        <div *ngIf="f.username.errors.required">Username is required</div>
                    </div>
                </mat-form-field>
                <mat-form-field appearance="outline" color="primary">
                    <mat-label for="password">Password</mat-label>
                    <input type="password" formControlName="password" matInput placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                    </div>
                </mat-form-field>
                <button mat-stroked-button color="primary" [disabled]="loading">
                    <span *ngIf="loading"></span>
                    Login
                </button>
                <div *ngIf="error">{{error}}</div>
            </form>
        </div>
    </div>
</div>
