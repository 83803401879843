import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioButton, MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule, MatDrawerContainer, MatDrawerContent, MatDrawer } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BasicAuthInterceptor } from './helpers/basic-auth.interceptor';
import { MainLayoutComponent } from './layout/components/main-layout/main-layout.component';
import { LoaderComponent } from './layout/components/loader/loader.component';
import { ToolbarComponent } from './layout/components/toolbar/toolbar.component';
import { NavigationComponent } from './layout/components/navigation/navigation.component';
import { LayoutModule } from './layout/layout.module';
import { PriceTickerService } from './services/price-ticker.service';
import { ReportDetailsComponent } from './components/report-details/report-details.component';
import { EditConfigDialogComponent } from './components/dialogs/edit-config-dialog/edit-config-dialog.component';
import { PropertyGridModule } from 'ngx-property-grid';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EditConfigDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,

    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,

    FlexLayoutModule,
    LayoutModule,

    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatToolbarModule,
    MatMenuModule,
    MatInputModule,
    MatRadioModule,
    MatIconModule,
    FormsModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    PropertyGridModule,
    NgJsonEditorModule
  ],
  providers: [
    PriceTickerService,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
        // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
