import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RefreshTimerComponent } from './refresh-timer.component';



@NgModule({
  declarations: [RefreshTimerComponent],
  imports: [
    CommonModule
  ],
  exports: [
    RefreshTimerComponent
  ]
})
export class RefreshTimerModule { }
