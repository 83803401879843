import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { GenericStorage } from '../../helpers/generic-storage';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private meta: Meta) { }

  renderTheme(theme: string) {
    this.document.body.classList.remove('vertical-purple-light', 'vertical-green-light', 'vertical-blue-light',
      'vertical-purple-dark', 'vertical-green-dark', 'vertical-blue-dark'
    );
    this.document.body.classList.add(theme);

    switch (theme) {
      case 'vertical-purple-light':
        this.meta.updateTag({ name: 'theme-color', content: '#52277c' });
        break;
      case 'vertical-green-light':
        this.meta.updateTag({ name: 'theme-color', content: '#cfea00' });
        break;
      case 'vertical-blue-light':
        this.meta.updateTag({ name: 'theme-color', content: '#1FA3CA' });
        break;
      case 'vertical-purple-dark':
        this.meta.updateTag({ name: 'theme-color', content: '#52277c' });
        break;
      case 'vertical-green-dark':
        this.meta.updateTag({ name: 'theme-color', content: '#cfea00' });
        break;
      case 'vertical-blue-dark':
        this.meta.updateTag({ name: 'theme-color', content: '#1FA3CA' });
        break;
    }
  }

  setTheme(theme: string) {
    GenericStorage.set('theme', theme);
    this.renderTheme(theme);
  }

  getTheme() {
    const themeName = GenericStorage.get('theme');
    if (themeName === 'vertical-blue-light' || themeName === 'vertical-purple-light' || themeName === 'vertical-green-light'
      || themeName === 'vertical-blue-dark' || themeName === 'vertical-purple-dark' || themeName === 'vertical-purple-dark') {
      return themeName;
    } else {
      return 'vertical-blue-light';
    }
  }
}
