import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TickerService {

  private baseUrl = `https://api.bitpanda.com/v1`;
  constructor(private httpClient: HttpClient) { }

  get(): Observable<Map<string, Map<string, number>>> {
    return this.httpClient
      .get<Map<string, Map<string, number>>>(`${this.baseUrl}/ticker`)
      .pipe(retry(2));
  }
}
