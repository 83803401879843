import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { TradeSummary } from '../models/trade-summary';
import { Trade } from '../models/trade';
import { Order } from '../models/order';
import { CoinCompare } from '../models/coin-compare';
import { BaseConfig, TraderConfig } from '../models/config';
import { Config } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class CointraderService {

  private baseUrl = `${environment.url}`;
  constructor(private httpClient: HttpClient) { }

  getSummary(start: string, end: string, exchange: string, instances: string[]): Observable<TradeSummary[]> {
    let ins = '';
    instances.forEach(i => ins = ins + `&instance=${i}`);
    return this.httpClient
      .get<TradeSummary[]>(`${this.baseUrl}/TradeSummary?fromDate=${start}&toDate=${end}&api=${exchange}${ins}`);
      // .pipe(retry(2));
  }

  getTrades(instances: string[], day: string, exchange: string, zeroOrders = false): Observable<Trade[]> {
    let ins = '';
    instances.forEach(i => ins = ins + `&instance=${i}`);

    
    return this.httpClient
    .get<Trade[]>(`${this.baseUrl}/Trades?day=${day}&api=${exchange}${ins}&zeroOrders=${zeroOrders}&virtual=true`);
  }

  getTrades2(instances: string[], day: string, exchange: string, zeroOrders = false): Observable<object> {
    let ins = '';
    instances.forEach(i => ins = ins + `&instance=${i}`);
    return this.httpClient
    .get(`${this.baseUrl}/Trades?day=${day}&api=${exchange}${ins}&zeroOrders=${zeroOrders}`);
  }

  getOrders(tradeID: number): Observable<Order[]> {
    return this.httpClient
    .get<Order[]>(`${this.baseUrl}/Orders/${tradeID}`);
  }

  getExchanges(): Observable<string[]> {
    return this.httpClient
    .get<string[]>(`${this.baseUrl}/Exchanges`);
  }

  getCoinCompares(): Observable<CoinCompare[]> {
    return this.httpClient
    .get<CoinCompare[]>(`${this.baseUrl}/CoinCompare`);
  }

  setTradeStatus(tradeID: number, status: string): Observable<boolean> {
    return this.httpClient
    .patch<boolean>(`${this.baseUrl}/Trades/${tradeID}?status=${status}`, null);
  }

  getOpenTrades(): Observable<Trade[]> {
    return this.httpClient
    .get<Trade[]>(`${this.baseUrl}/Trades/openorders`);
  }

  getConfigs(instances: string[], exchange = '' ): Observable<BaseConfig[]> {
    let ins = '';
    instances.forEach(i => ins = ins + `&instance=${i}`);
    return this.httpClient
    .get<BaseConfig[]>(`${this.baseUrl}/Configs?api=${exchange}${ins}`);
  }

  getConfig(id: number): Observable<BaseConfig> {
    return this.httpClient
    .get<BaseConfig>(`${this.baseUrl}/Configs/${id}`);
  }

  updateConfig(id: number, config: BaseConfig): Observable<BaseConfig> {
    return this.httpClient
      .put<BaseConfig>(`${this.baseUrl}/Configs/${id}`, config);
  }

  createConfig(config: BaseConfig): Observable<BaseConfig> {
    return this.httpClient
      .post<BaseConfig>(`${this.baseUrl}/Configs`, config);
  }
}
