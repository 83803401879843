import { Component } from '@angular/core';
import { RouterTransition } from './layout/animations/router-transition';
import { Observable, merge } from 'rxjs';
import { User } from './models/user';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { LoaderService } from './layout/services/loader.service';
import { UserSettingsService } from './layout/services/user-settings.service';
import { filter, mapTo } from 'rxjs/operators';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

@Component({
  animations: [RouterTransition],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ig-trader';
  isRouting$: Observable<boolean>;
  user$: Observable<User>;

  constructor(
    router: Router,
    authService: AuthenticationService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private userSettingsService: UserSettingsService,
    private loaderService: LoaderService,
  ) {
    this.user$ = authService.currentUser;
    this.registerIcons();
    this.initTheme();

    const navigationStart$ = router.events.pipe(
      filter(event => event instanceof NavigationStart),
      mapTo(true)
    );

    const navigationEnd$ = router.events.pipe(
      filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError),
      mapTo(false)
    );

    this.isRouting$ = merge(navigationStart$, navigationEnd$).pipe();
    this.isRouting$.subscribe(value => {
      value ? this.loaderService.show() : this.loaderService.hide();
    });

    registerLocaleData(localeDe, 'de');

  }

  private initTheme() {
    // render last set theme
    const theme = this.userSettingsService.getTheme();
    this.userSettingsService.renderTheme(theme);
  }

  private registerIcons() {
    this.iconRegistry.addSvgIcon('vertical-loader', this.sanitizer.bypassSecurityTrustResourceUrl('assets/loader/vertical.svg'));

    // ./layout
    this.iconRegistry.addSvgIcon('arrow_drop_down',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/layout/ic_arrow_drop_down_black_24px.svg'));
    this.iconRegistry.addSvgIcon('back', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/layout/ic_arrow_back_black_24px.svg'));
    this.iconRegistry.addSvgIcon('lifecycle', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/layout/baseline-360-24px.svg'));
    this.iconRegistry.addSvgIcon('menu', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/layout/ic_menu_black_24px.svg'));
    this.iconRegistry.addSvgIcon('logo_white', this.sanitizer.bypassSecurityTrustResourceUrl('assets/vertical_logo_white.svg'));

    // exchanges
    this.iconRegistry.addSvgIcon('binance', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/binance.svg'));
    this.iconRegistry.addSvgIcon('gateio', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/gateio.svg'));
    this.iconRegistry.addSvgIcon('bitget', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/bitget.svg'));
    this.iconRegistry.addSvgIcon('bybit', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/bybit.svg'));
    this.iconRegistry.addSvgIcon('bitfinex', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/bitfinex.svg'));
    this.iconRegistry.addSvgIcon('digifinex', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/digifinex.svg'));
    this.iconRegistry.addSvgIcon('hitbtc', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/hitbtc.svg'));
    this.iconRegistry.addSvgIcon('poloniex', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/poloniex.svg'));
    this.iconRegistry.addSvgIcon('huobi', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/huobi.svg'));
    this.iconRegistry.addSvgIcon('hotbit', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/hotbit.svg'));
    this.iconRegistry.addSvgIcon('okex', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/okex.svg'));
    this.iconRegistry.addSvgIcon('coinall', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/coinall.svg'));
    this.iconRegistry.addSvgIcon('bitz', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/bitz.svg'));
    this.iconRegistry.addSvgIcon('kucoin', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/kucoin.svg'));
    this.iconRegistry.addSvgIcon('bittrex', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/b.svg'));
    this.iconRegistry.addSvgIcon('coinbase', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/coinbase.svg'));
    this.iconRegistry.addSvgIcon('kraken', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/kraken.svg'));
    this.iconRegistry.addSvgIcon('mexc', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/mexc.svg'));
    this.iconRegistry.addSvgIcon('lbank', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/lbank.svg'));
    this.iconRegistry.addSvgIcon('bitmart', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/bitmart.svg'));
    this.iconRegistry.addSvgIcon('ascendex', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/ascendex.svg'));
    this.iconRegistry.addSvgIcon('biconomy', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/biconomy.svg'));
    this.iconRegistry.addSvgIcon('coinex', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/coinex.svg'));
    this.iconRegistry.addSvgIcon('bingx', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/bingx.svg'));
    this.iconRegistry.addSvgIcon('crypto', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/crypto.svg'));
    this.iconRegistry.addSvgIcon('xt', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exchanges/xt.svg'));
      
    // ./misc
    this.iconRegistry.addSvgIcon('add', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/add.svg'));
    this.iconRegistry.addSvgIcon('add_circle', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/add_circle.svg'));
    this.iconRegistry.addSvgIcon('add_circle_outline',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/add_circle_outline.svg'));
    this.iconRegistry.addSvgIcon('alert', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/alert.svg'));
    this.iconRegistry.addSvgIcon('close', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/close.svg'));
    this.iconRegistry.addSvgIcon('cogs', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/cogs.svg'));
    this.iconRegistry.addSvgIcon('configuration', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/configuration.svg'));
    this.iconRegistry.addSvgIcon('create', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/create.svg'));
    this.iconRegistry.addSvgIcon('customer', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/customer.svg'));
    this.iconRegistry.addSvgIcon('dashboard', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/dashboard.svg'));
    this.iconRegistry.addSvgIcon('delete', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/delete.svg'));
    this.iconRegistry.addSvgIcon('done_all', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/done_all.svg'));
    this.iconRegistry.addSvgIcon('edit', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/edit.svg'));
    this.iconRegistry.addSvgIcon('filter', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/filter.svg'));
    this.iconRegistry.addSvgIcon('generator', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/generator.svg'));
    this.iconRegistry.addSvgIcon('login', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/login.svg'));
    this.iconRegistry.addSvgIcon('organization', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/location_city.svg'));
    this.iconRegistry.addSvgIcon('search', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/misc/search.svg'));

    // ./product-management
    this.iconRegistry.addSvgIcon('partially_linked',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/product-management/partially_linked.svg'));
    this.iconRegistry.addSvgIcon('not_linked',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/product-management/not_linked.svg'));
    this.iconRegistry.addSvgIcon('linked', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/product-management/linked.svg'));

    // ./notification
    this.iconRegistry.addSvgIcon('error', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/notification/error.svg'));
    this.iconRegistry.addSvgIcon('info', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/notification/info.svg'));
    this.iconRegistry.addSvgIcon('success', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/notification/success.svg'));
    this.iconRegistry.addSvgIcon('warning', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/notification/warning.svg'));
  }
}
