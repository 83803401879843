import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PriceTicker } from '../models/priceTicker';
import { TickerService } from '../layout/services/ticker.service';

@Injectable({
  providedIn: 'root'
})
export class PriceTickerService {
  private currentPriceTickerSubject = new BehaviorSubject<PriceTicker>(null);
  public currentPriceTicker$: Observable<PriceTicker>;

  constructor(
    private tickerService: TickerService
  ) {
    this.currentPriceTicker$ = this.currentPriceTickerSubject.asObservable();
    this.getData();
    setInterval(() => this.getData(), 10000);
  }

  public get currentPriceTickerValue(): PriceTicker {
    return this.currentPriceTickerSubject.value;
  }

  public async getData() {
    const ti = await this.tickerService.get().toPromise();

    const ticker = new PriceTicker();

    const btcusd = ti['BTC']['USD'];
    ticker.BTCUSD = btcusd;

    const btceur = ti['BTC']['EUR'];
    ticker.BTCEUR = btceur;

    const ethusd = ti['ETH']['USD'];
    ticker.ETHUSD = ethusd;

    const etheur = ti['ETH']['EUR'];
    ticker.ETHEUR = etheur;

    this.currentPriceTickerSubject.next(ticker);
  }
}
