import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { RouterOutlet } from '@angular/router';
import { RouterTransition } from '../../animations/router-transition';
import { User } from '../../../models/user';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  animations: [RouterTransition],
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {

  user$: Observable<User>;

  constructor(authService: AuthenticationService) {
    this.user$ = authService.currentUser;
   }

   getState(outlet: RouterOutlet) {
    if (!outlet.isActivated) {
      return;
    }
    return {
      value: outlet.activatedRoute.url
    };
  }

}
